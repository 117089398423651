import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { volunteeringVolunteerImage6 } from 'assets/learning';
import { Modal } from 'components/index.js';

import VolunteeringLearningSimulation1 from './simulation-1';

const VolunteeringLearningScreen7 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-2-3" className='text-volunteering'>{t('learning.volunteering.volunteer.40')}</h4>
      <p>{t('learning.volunteering.volunteer.41')}</p>
      <h4 id="step-2-3" className='text-volunteering'>{t('learning.volunteering.volunteer.42')}</h4>
      <ul>
        <li>{t('learning.volunteering.volunteer.43.1')}</li>
        <li>{t('learning.volunteering.volunteer.43.2')}</li>
        <li>{t('learning.volunteering.volunteer.43.3')}</li>
        <li>{t('learning.volunteering.volunteer.43.4')}</li>
        <li>{t('learning.volunteering.volunteer.43.5')}</li>
        <li>{t('learning.volunteering.volunteer.43.6')}</li>
      </ul>
      <ContentModal />
      <br/>
      <br/>
      <Image src={volunteeringVolunteerImage6} width={'100%'} height={400} style={{ objectFit: 'cover'}} />
    </>
  )
}

const ContentModal = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className='text-center mt-5'>
        <button onClick={() => setVisible(true)} className='btn btn-volunteering text-white'>{t('learning.volunteering.volunteer.simulation.1')}</button>
      </div>
      <Modal show={visible} onHide={() => setVisible(false)} className={'p-0'} size={'xl'}>
        <VolunteeringLearningSimulation1 />
      </Modal>
    </>
  );
}

export default VolunteeringLearningScreen7;