import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { volunteerWhite } from 'assets/icons/index.js';
const VolunteeringLearningSimulation2 = () => {
  const { t } = useTranslation();

  return (
    <Container className='page'>
      <table className='table two-columns text-volunteering' style={{ width: '100%' }}>
        <colgroup>
          <col className='w-10'></col>
          <col></col>
        </colgroup>
        <thead>
          <tr>
            <th>{t('learning.volunteering.organization.85.1')}</th>
            <th>{t('learning.volunteering.organization.85.2')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('learning.volunteering.organization.86.1')}</td>
            <td>{t('learning.volunteering.organization.86.2')}</td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.organization.87.1')}</td>
            <td>{t('learning.volunteering.organization.87.2')}</td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.organization.88.1')}</td>
            <td>{t('learning.volunteering.organization.88.2')}</td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.organization.89.1')}</td>
            <td>
              <ul>
                <li>{t('learning.volunteering.organization.89.2')}</li>
                <li>{t('learning.volunteering.organization.89.3')}</li>
                <li>
                  {t('learning.volunteering.organization.89.4')}
                  <a href='https://dotstorming.com/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://dotstorming.com/</a>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.organization.90.1')}</td>
            <td>
              <ul>
                <li>{t('learning.volunteering.organization.90.2')}</li>
                <li>{t('learning.volunteering.organization.90.3')}</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.organization.91.1')}</td>
            <td>
              <ul>
                <li>{t('learning.volunteering.organization.91.2')}</li>
                <li>{t('learning.volunteering.organization.91.3')}</li>
                <li>
                  {t('learning.volunteering.organization.91.4')}
                  <a href='https://dotstorming.com/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.91.5')}</a>
                  {t('learning.volunteering.organization.91.6')}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.organization.92.1')}</td>
            <td>
              <ul>
                <li>{t('learning.volunteering.organization.92.2')}</li>
                <li>{t('learning.volunteering.organization.92.3')}</li>
                <li>{t('learning.volunteering.organization.92.4')}</li>
                <li>{t('learning.volunteering.organization.92.5')}</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.organization.93.1')}</td>
            <td>
              {t('learning.volunteering.organization.93.2')}
              <a href='https://www.mindtools.com/aes9isg/team-building-exercises-problem-solving-decision-making' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.93.3')}</a>
              {t('learning.volunteering.organization.93.4')}
              <a href='https://www.salto-youth.net/tools/toolbox/tool/hot-air-balloon.2942/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.93.5')}</a>
            </td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.organization.94.1')}</td>
            <td>{t('learning.volunteering.organization.94.2')}</td>
          </tr>
        </tbody>
      </table>
    </Container>
  )

}

export default VolunteeringLearningSimulation2;