import { volunteeringOrganizationIllustration18 } from 'assets/learning';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppConstants } from 'constant';
import { Image } from 'react-bootstrap';

const { PATH_VOLUNTEERING_LEARNING_ORGANIZATION } = AppConstants;

const VolunteeringLearningScreen10 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-3-3" className='text-volunteering'>{t('learning.volunteering.organization.119')}</h4>
      <div className='d-flex flex-direction-row'>
        <Image src={volunteeringOrganizationIllustration18} width={400} height={350} />
        <div className='mt-4 pt-4 pl-4'>
          <p>{t('learning.volunteering.organization.120')}</p>
          <p>
            {t('learning.volunteering.organization.121.1')}
            <a href={PATH_VOLUNTEERING_LEARNING_ORGANIZATION + '?page=6'} rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.121.2')}</a>
            {t('learning.volunteering.organization.121.3')}
          </p>
          <p>
            {t('learning.volunteering.organization.122.1')}
            <a href='https://view.genially.com/664f66d76fc251001484d7f4/interactive-content-genially-sin-titulo' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.122.2')}</a>
            {t('learning.volunteering.organization.122.3')}
          </p>
        </div>
      </div>
    </>
  )

}

export default VolunteeringLearningScreen10;