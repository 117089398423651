import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { volunteeringOrganizationPhoto27 } from 'assets/learning';
import { Image } from 'react-bootstrap';
import { Modal } from 'components/index.js';

import VolunteeringLearningSimulation5 from './simulation-5';

const VolunteeringLearningScreen15 = () => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ marginTop: -32 }}>
        <Image src={volunteeringOrganizationPhoto27} width={'100%'} height={240} style={{ objectFit: 'cover' }} />
      </div>
      <br />
      <h2 id="step-5">{t('learning.volunteering.organization.180')}</h2>
      <h4 id="step-5-1" className='text-volunteering'>{t('learning.volunteering.organization.181')}</h4>

      <p>{t('learning.volunteering.organization.182')}</p>
      <p>
        {t('learning.volunteering.organization.183.1')}
        <b>{t('learning.volunteering.organization.183.2')}</b>
        {t('learning.volunteering.organization.183.3')}
        <b>{t('learning.volunteering.organization.183.4')}</b>
        {t('learning.volunteering.organization.183.5')}
      </p>
      <p>
        {t('learning.volunteering.organization.184.1')}
        <b>{t('learning.volunteering.organization.184.2')}</b>
        {t('learning.volunteering.organization.184.3')}
      </p>
      <p>
        {t('learning.volunteering.organization.185.1')}
        <b>{t('learning.volunteering.organization.185.2')}</b>
        {t('learning.volunteering.organization.185.3')}
        <b>{t('learning.volunteering.organization.185.4')}</b>
        {t('learning.volunteering.organization.185.5')}
      </p>
      <p>{t('learning.volunteering.organization.186')}</p>

      <ContentModal />
    </>
  )
}

const ContentModal = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className='text-center mt-5'>
        <button onClick={() => setVisible(true)} className='btn btn-volunteering text-white'>{t('learning.volunteering.organization.simulation.5')}</button>
      </div>
      <Modal show={visible} onHide={() => setVisible(false)} className={'p-0'} size={'xl'}>
        <VolunteeringLearningSimulation5 />
      </Modal>
    </>
  );
}


export default VolunteeringLearningScreen15;