import { volunteeringOrganizationIllustration4, volunteeringOrganizationPhoto2 } from 'assets/learning';
import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/index.js';

import VolunteeringLearningSimulation1 from './simulation-1';

const VolunteeringLearningScreen3 = () => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ marginTop: -32 }}>
        <Image src={volunteeringOrganizationPhoto2} width={'100%'} height={240} style={{ objectFit: 'cover' }} />
      </div>
      <br />
      <h4 id="step-1-2" className='text-volunteering'>{t('learning.volunteering.organization.9')}</h4>
      <p>{t('learning.volunteering.organization.10')}</p>
      <p>{t('learning.volunteering.organization.11')}</p>
      <div className='d-flex flex-direction-row'>
        <Image src={volunteeringOrganizationIllustration4} width={400} height={350} />
        <div className='mt-4 pl-4'>
          <p className='mt-4'><b>{t('learning.volunteering.organization.12')}</b></p>
          <p><b>{t('learning.volunteering.organization.13.1')}:</b> {t('learning.volunteering.organization.13.2')}</p>
          <p><b>{t('learning.volunteering.organization.14.1')}:</b> {t('learning.volunteering.organization.14.2')}</p>
          <p><b>{t('learning.volunteering.organization.15.1')}:</b> {t('learning.volunteering.organization.15.2')}</p>
        </div>
      </div>
      <p className='mt-4'><b>{t('learning.volunteering.organization.16')}</b></p>
      <p><b>{t('learning.volunteering.organization.17.1')}:</b> {t('learning.volunteering.organization.17.2')}</p>
      <p><b>{t('learning.volunteering.organization.18.1')}:</b> {t('learning.volunteering.organization.18.2')}</p>
      <p><b>{t('learning.volunteering.organization.19.1')}:</b> {t('learning.volunteering.organization.19.2')}</p>
      <p>{t('learning.volunteering.organization.20')}</p>
      <ContentModal />
    </>
  )

}

const ContentModal = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className='text-center mt-5'>
        <button onClick={() => setVisible(true)} className='btn btn-volunteering text-white'>{t('learning.volunteering.organization.simulation.1')}</button>
      </div>
      <Modal show={visible} onHide={() => setVisible(false)} className={'p-0'} size={'xl'}>
        <VolunteeringLearningSimulation1 />
      </Modal>
    </>
  );
}

export default VolunteeringLearningScreen3;