import { volunteeringOrganizationPhoto184 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen9 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-3-2" className='text-volunteering'>{t('learning.volunteering.organization.114')}</h4>
      <p>{t('learning.volunteering.organization.115')}</p>
      <p>{t('learning.volunteering.organization.116')}</p>
      <Image src={volunteeringOrganizationPhoto184} width={'100%'} />
      <br />
      <p>{t('learning.volunteering.organization.117')}</p>
      <p>{t('learning.volunteering.organization.118')}</p>
    </>
  )

}

export default VolunteeringLearningScreen9;