import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion';
import { Image } from 'react-bootstrap';
import { volunteeringOrganizationIllustration16, volunteeringOrganizationPhoto141, volunteeringOrganizationPhoto142 } from 'assets/learning';
import { Modal } from 'components/index.js';

import VolunteeringLearningSimulation2 from './simulation-2';

const VolunteeringLearningScreen8 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-2-3" className='text-volunteering'>{t('learning.volunteering.organization.64')}</h4>
      <p>{t('learning.volunteering.organization.65')}</p>

      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t('learning.volunteering.organization.66')}</Accordion.Header>
          <Accordion.Body>
            <p>{t('learning.volunteering.organization.67')}</p>
            <p>
              <i>{t('learning.volunteering.organization.68.1')}</i>
              <a href='https://www.youtube.com/watch?v=jbV1TDZQAFc' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.68.2')}</a>
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t('learning.volunteering.organization.69')}</Accordion.Header>
          <Accordion.Body>
            <p>{t('learning.volunteering.organization.70')}</p>
            <p>
              <i>{t('learning.volunteering.organization.71.1')}</i>
              <a href='https://www.youtube.com/watch?v=7hl5adqpYdQ' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.71.2')}</a>
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t('learning.volunteering.organization.72')}</Accordion.Header>
          <Accordion.Body>
            <p>{t('learning.volunteering.organization.73')}</p>
            <p>
              <i>{t('learning.volunteering.organization.74.1')}</i>
              <a href='https://www.youtube.com/watch?v=7yBUBmciQBk' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.74.2')}</a>
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t('learning.volunteering.organization.75')}</Accordion.Header>
          <Accordion.Body>
            <p>{t('learning.volunteering.organization.76')}</p>
            <p>
              <i>{t('learning.volunteering.organization.77.1')}</i>
              <a href='https://www.youtube.com/watch?v=BDpHhELO_b0' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.77.2')}</a>
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t('learning.volunteering.organization.78')}</Accordion.Header>
          <Accordion.Body>
            <p>{t('learning.volunteering.organization.79')}</p>
            <p><i>{t('learning.volunteering.organization.80')}</i></p>
            <Image src={volunteeringOrganizationPhoto141} width={'100%'} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t('learning.volunteering.organization.81')}</Accordion.Header>
          <Accordion.Body>
            <p>{t('learning.volunteering.organization.82')}</p>
            <p><i>{t('learning.volunteering.organization.83')}</i></p>
            <Image src={volunteeringOrganizationPhoto142} width={'100%'} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <p>{t('learning.volunteering.organization.84')}</p>

      <div className='text-center'>
        <Image src={volunteeringOrganizationIllustration16} width={400} height={350} />
      </div>

      <ContentModal />
    </>
  )

}

const ContentModal = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className='text-center mt-5'>
        <button onClick={() => setVisible(true)} className='btn btn-volunteering text-white'>{t('learning.volunteering.organization.simulation.2')}</button>
      </div>
      <Modal show={visible} onHide={() => setVisible(false)} className={'p-0'} size={'xl'}>
        <VolunteeringLearningSimulation2 />
      </Modal>
    </>
  );
}


export default VolunteeringLearningScreen8;