import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningSimulation5 = () => {
  const { t } = useTranslation();

  return (
    <Container className='page'>
      <table className='table two-columns text-volunteering' style={{ width: '100%' }}>
        <colgroup>
          <col className='w-10'></col>
          <col></col>
        </colgroup>
        <thead>
          <tr>
            <th>{t('learning.volunteering.organization.187.1')}</th>
            <th>{t('learning.volunteering.organization.187.2')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('learning.volunteering.organization.188.1')}</td>
            <td>{t('learning.volunteering.organization.188.2')}</td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.organization.189.1')}</td>
            <td>{t('learning.volunteering.organization.189.2')}</td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.organization.190.1')}</td>
            <td>{t('learning.volunteering.organization.190.2')}</td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.organization.191.1')}</td>
            <td>
              <ul>
                <li>{t('learning.volunteering.organization.191.2')}</li>
                <li><a href='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG0tWvObIlNtYrhmFetsXQlRrOQlcuXQQehLTpjhcJL7oZoUoQENyhdLDiZWFnKPOKZAI&usqp=CAU' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.191.3')}</a></li>
                <li><a href='https://drive.google.com/file/d/16DpowNX80G9sN2pOU769GTl8JIGXSz8P/view?usp=drive_link' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.191.4')}</a></li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.organization.192.1')}</td>
            <td>
              <ul>
                <li>{t('learning.volunteering.organization.192.2')}</li>
                <li>{t('learning.volunteering.organization.192.3')}</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.organization.193.1')}</td>
            <td>
              <ul>
                <li>{t('learning.volunteering.organization.193.2')}</li>
                <li>{t('learning.volunteering.organization.193.3')}</li>
                <li>{t('learning.volunteering.organization.193.4')}</li>
                <li>{t('learning.volunteering.organization.193.5')}</li>
                <li>{t('learning.volunteering.organization.193.6')}</li>
                <li>{t('learning.volunteering.organization.193.7')}</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.organization.194.1')}</td>
            <td>{t('learning.volunteering.organization.194.2')}</td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.organization.195.1')}</td>
            <td>
              {t('learning.volunteering.organization.195.2')}
              <a href='https://mentorup-project.eu/wp-content/uploads/2023/01/Mentor-Up-Manual.pdf' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.195.3')}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </Container>
  )

}

export default VolunteeringLearningSimulation5;