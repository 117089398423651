import { volunteeringVolunteerIllustration25, volunteeringVolunteerImage17 } from 'assets/learning';
import React, { useState } from 'react';
import { Accordion, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/index.js';

import VolunteeringLearningSimulation2 from './simulation-2';


const VolunteeringLearningScreen16 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-5-4" className='text-volunteering'>{t('learning.volunteering.volunteer.106')}</h4>
      <div className='d-flex flex-direction-row'>
        <Image src={volunteeringVolunteerIllustration25} width={400} height={300} />
        <div className='pl-4'>
          <p>{t('learning.volunteering.volunteer.107')}</p>
          <p>{t('learning.volunteering.volunteer.108')}</p>
          <p>{t('learning.volunteering.volunteer.109')}</p>
          <p>{t('learning.volunteering.volunteer.110')}</p>
        </div>
      </div>

      <div className='box mb-5'>
        <i>
          {t('learning.volunteering.volunteer.111.1')}
          <a href="https://www.youtube.com/watch?v=n0uwTBrgqxI" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.111.2')}</a>
          {t('learning.volunteering.volunteer.111.3')}
        </i>
      </div>
      <Accordion activeKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            <p><b>{t('learning.volunteering.volunteer.112')}</b></p>
            <p>{t('learning.volunteering.volunteer.113')}</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion activeKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            <p><b>{t('learning.volunteering.volunteer.114')}</b></p>
            <p>{t('learning.volunteering.volunteer.115')}</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion activeKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            <p><b>{t('learning.volunteering.volunteer.116')}</b></p>
            <p>{t('learning.volunteering.volunteer.117')}</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <br/>
      <br/>
      <table className='table table-bordered three-columns text-volunteering'>
        <tbody>
          <tr>
            <th>{t('learning.volunteering.volunteer.118.1')}</th>
            <th>{t('learning.volunteering.volunteer.118.2')}</th>
            <th>{t('learning.volunteering.volunteer.118.3')}</th>
            <th>{t('learning.volunteering.volunteer.118.4')}</th>
            <th>{t('learning.volunteering.volunteer.118.5')}</th>
            <th>{t('learning.volunteering.volunteer.118.6')}</th>
            <th>{t('learning.volunteering.volunteer.118.7')}</th>
          </tr>
          <tr>
            <td>{t('learning.volunteering.volunteer.119')}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.volunteer.120')}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.volunteer.121')}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <ContentModal />
      <br/>
      <br/>
      <Image src={volunteeringVolunteerImage17} width={'100%'} height={400} />
    </>
  )
}

const ContentModal = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className='text-center mt-5'>
        <button onClick={() => setVisible(true)} className='btn btn-volunteering text-white'>{t('learning.volunteering.volunteer.simulation.2')}</button>
      </div>
      <Modal show={visible} onHide={() => setVisible(false)} className={'p-0'} size={'xl'}>
        <VolunteeringLearningSimulation2 />
      </Modal>
    </>
  );
}

export default VolunteeringLearningScreen16;