import { volunteeringVolunteerIllustration21, volunteeringVolunteerImage11 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen11 = () => {
  const { t } = useTranslation();

  return (
    <>
      <Image src={volunteeringVolunteerImage11} width={'100%'} height={440} style={{ marginTop: -32, objectFit: 'cover' }} />
      <br/><br/>
      <h2 id="step-5">{t('learning.volunteering.volunteer.74')}</h2>

      <h4 id="step-5-1" className='text-volunteering'>{t('learning.volunteering.volunteer.75')}</h4>
      <p>{t('learning.volunteering.volunteer.76')}</p>
      <div className='d-flex flex-direction-row'>
        <Image src={volunteeringVolunteerIllustration21} width={400} height={300} />
        <div className='mt-4 pt-5 pl-4'>
          <div className='box mb-5'>
            <i>
              {t('learning.volunteering.volunteer.77.1')}
              <a href="https://www.youtube.com/watch?v=iONDebHX9qk" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.77.2')}</a>
              <br/>
              {t('learning.volunteering.volunteer.78.1')}
              <a href="https://www.rescuetime.com/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.78.2')}</a>
              {t('learning.volunteering.volunteer.78.3')}
              <a href="https://todoist.com/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.78.4')}</a>.
            </i>
          </div>
        </div>
      </div>
      <br/>
      <h4 id="step-5-2" className='text-volunteering'>{t('learning.volunteering.volunteer.79')}</h4>
      <p>
        {t('learning.volunteering.volunteer.80.1')}
        <ul>
          <li><b>{t('learning.volunteering.volunteer.80.2')}</b>: {t('learning.volunteering.volunteer.80.3')}</li>
          <li><b>{t('learning.volunteering.volunteer.80.4')}</b>: {t('learning.volunteering.volunteer.80.5')}</li>
          <li><b>{t('learning.volunteering.volunteer.80.6')}</b>: {t('learning.volunteering.volunteer.80.7')}</li>
          <li><b>{t('learning.volunteering.volunteer.80.8')}</b>: {t('learning.volunteering.volunteer.80.9')}</li>
          <li><b>{t('learning.volunteering.volunteer.80.10')}</b>: {t('learning.volunteering.volunteer.80.11')}</li>
          <li><b>{t('learning.volunteering.volunteer.80.12')}</b>: {t('learning.volunteering.volunteer.80.13')}</li>
          <li><b>{t('learning.volunteering.volunteer.80.14')}</b>: {t('learning.volunteering.volunteer.80.15')}</li>
          <li><b>{t('learning.volunteering.volunteer.80.16')}</b>: {t('learning.volunteering.volunteer.80.17')}</li>
        </ul>
      </p>
      <div className='box mb-5'>
        <i>
          {t('learning.volunteering.volunteer.81.1')}<br/>
          <a href="https://www.youtube.com/watch?v=1-SvuFIQjK8" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.81.2')}</a>
          <a href="https://www.youtube.com/watch?v=geRKHFzTxNY" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.81.3')}</a>
          <a href="https://www.youtube.com/watch?v=mNBmG24djoY" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.81.4')}</a>
        </i>
      </div>
    </>
  )

}

export default VolunteeringLearningScreen11;