import React from 'react';
import { useTranslation } from 'react-i18next';
import { volunteeringOrganizationIllustration31 } from 'assets/learning';
import { Image } from 'react-bootstrap';

const VolunteeringLearningScreen13 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-2" className='text-volunteering'>{t('learning.volunteering.organization.155')}</h4>
      <p>{t('learning.volunteering.organization.156')}</p>

      <div className='d-flex flex-direction-row'>
        <Image src={volunteeringOrganizationIllustration31} width={400} height={350} />
        <div className='mt-4 pt-4 pl-4'>
          <h6>{t('learning.volunteering.organization.157')}</h6>
          <p>
            {t('learning.volunteering.organization.158.1')}
            <a href='https://www.youtube.com/watch?v=U4IU-y9-J8Q' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.158.2')}</a>
            {t('learning.volunteering.organization.158.3')}
          </p>

          <h6>{t('learning.volunteering.organization.159')}</h6>
          <p>
            {t('learning.volunteering.organization.160.1')}
            <a href='https://teambuilding.com/blog/virtual-scavenger-hunt' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.160.2')}</a>
            {t('learning.volunteering.organization.160.3')}
          </p>
        </div>
      </div>

      <h6>{t('learning.volunteering.organization.161')}</h6>
      <p>
        {t('learning.volunteering.organization.162.1')}
        <a href='https://zoom.us/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.162.2')}</a>
        {t('learning.volunteering.organization.162.3')}
        <a href='https://meet.google.com/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.162.4')}</a>
        {t('learning.volunteering.organization.162.5')}
      </p>

      <h6>{t('learning.volunteering.organization.163')}</h6>
      <p>{t('learning.volunteering.organization.164')}</p>

      <h6>{t('learning.volunteering.organization.165')}</h6>
      <p>{t('learning.volunteering.organization.166')}</p>

    </>
  )
}

export default VolunteeringLearningScreen13;