import { volunteeringVolunteerIllustration28 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen17 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 className='text-volunteering mb-0 pb-0'>{t('learning.volunteering.volunteer.132')}</h4>
      <div className='d-flex flex-direction-row'>
        <Image src={volunteeringVolunteerIllustration28} width={400} height={300} />
        <div className='mt-4 pt-5 pl-4'>
          <br/>
          <p>{t('learning.volunteering.volunteer.134')}</p>
        </div>
      </div>
      <p>{t('learning.volunteering.volunteer.134')}</p>
    </>
  )

}

export default VolunteeringLearningScreen17;