import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { volunteeringOrganizationIllustration37, volunteeringOrganizationPhoto37 } from 'assets/learning';

const VolunteeringLearningScreen19 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-6-2" className='text-volunteering'>{t('learning.volunteering.organization.246')}</h4>
      <p>{t('learning.volunteering.organization.247')}</p>

      <p>
        {t('learning.volunteering.organization.248.1')}
        <b>{t('learning.volunteering.organization.248.2')}</b>
        {t('learning.volunteering.organization.248.3')}
        <b>{t('learning.volunteering.organization.248.4')}</b>
        {t('learning.volunteering.organization.248.5')}
        <b>{t('learning.volunteering.organization.248.6')}</b>
        {t('learning.volunteering.organization.248.7')}
      </p>

      <div className='d-flex flex-direction-row'>
        <Image src={volunteeringOrganizationIllustration37} width={400} height={350} />
        <div className='mt-4 pt-4 pl-4'>
          <p>
            {t('learning.volunteering.organization.249.1')}
            <b>{t('learning.volunteering.organization.249.2')}</b>
            {t('learning.volunteering.organization.249.3')}
            <b>{t('learning.volunteering.organization.249.4')}</b>
            {t('learning.volunteering.organization.249.5')}
            <b>{t('learning.volunteering.organization.249.6')}</b>
            {t('learning.volunteering.organization.249.7')}
            <b>{t('learning.volunteering.organization.249.8')}</b>
            {t('learning.volunteering.organization.249.9')}
          </p>
          <p>
            {t('learning.volunteering.organization.250.1')}
            <b>{t('learning.volunteering.organization.250.2')}</b>
            {t('learning.volunteering.organization.250.3')}
            <b>{t('learning.volunteering.organization.250.4')}</b>
            {t('learning.volunteering.organization.250.5')}
          </p>
        </div>
      </div>

      <p>
        {t('learning.volunteering.organization.251.1')}
        <b>{t('learning.volunteering.organization.251.2')}</b>
        {t('learning.volunteering.organization.251.3')}
        <b>{t('learning.volunteering.organization.251.4')}</b>
        {t('learning.volunteering.organization.251.5')}
        <b>{t('learning.volunteering.organization.251.6')}</b>
        {t('learning.volunteering.organization.251.7')}
      </p>

      <p>
        {t('learning.volunteering.organization.252.1')}
        <b>{t('learning.volunteering.organization.252.2')}</b>
        {t('learning.volunteering.organization.252.3')}
      </p>
    </>
  )
}

export default VolunteeringLearningScreen19;